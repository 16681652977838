import * as Sentry from '@sentry/nextjs'
import { addHost } from '@zupr/api'
import {
    AggregationRequest,
    AggregationResponse,
    Aggregations,
    Variables,
} from '@zupr/types/fo'
import { Item, List } from '@zupr/types/generic'
import { addQueryToUrl } from '@zupr/utils/url'


import { NextApiRequest } from 'next'
import { NextRequest } from 'next/server'
import { getCache, setCache } from './cache'
import { proxyHeaders } from './monitoring'

interface GetProps {
    url: string
    variables?: any
    ex?: number
}

interface GetItemProps extends GetProps {
    pause?: boolean
}

interface GetRelationProps extends GetProps {
    pause?: boolean
}

interface GetListProps extends GetProps {
    all?: boolean
    pause?: boolean
}

interface GetAggregationProps extends GetProps {
    url: string
    variables?: Variables
    aggregation: AggregationRequest
}

export const filterEmptyAggregations = (
    aggregations: AggregationResponse['aggregations'],
    base?: string
) => {
    if (!aggregations) return {}

    const unbased = aggregations[base] || aggregations

    if (!unbased) return {}

    const unfiltered = unbased.filtered || unbased

    if (typeof unfiltered !== 'object') return {}

    let notEmpty = {}
    Object.keys(unfiltered).forEach((aggregation) => {
        if (unfiltered[aggregation].buckets?.length) {
            notEmpty[aggregation] = unfiltered[aggregation]
        }
    })
    return Object.keys(notEmpty).length ? notEmpty : {}
}

export async function get<ResponseData = any>({
    url,
    variables,
    ex,
}: GetProps, req?: NextRequest | NextApiRequest): Promise<[ResponseData, Response | null]> {
    const fullUrl =
        (variables && addHost(addQueryToUrl(url, variables))) || addHost(url)

    console.log('requesting', fullUrl)

    const cache = await getCache(fullUrl)
    if (cache) {
        return [cache, undefined]
    }

    const xHeaders = proxyHeaders(req?.headers as Record<string, string>) 
    const headers = {
        'Content-Type': 'application/json',
        ...xHeaders
    }

    const response = await fetch(fullUrl, { 
        method: 'GET',
        headers 
    })

    if (!response.ok && response.status >= 500) {
        throw response
    }

    const responseHeaders = []
    response.headers.forEach((value, key) => {
        responseHeaders[`${key}: ${value}`]
    })

    Sentry.addBreadcrumb({
        category: 'xhr',
        message: `Fetching fo api responded with status "${response.status}" for url ${fullUrl}`,
        level: 'info',
    });

    // when there is no correct response
    if (response.status >= 300) {
        Sentry.addBreadcrumb({
            category: 'xhr',
            message: `Fetching fo api responded with error status "${response.status}"`,
            level: 'error',
            data: {
                url,
                variables,
                headers: responseHeaders
            }
        });
        return [null, response]
    }

    // do the request
    const data = await response.json()

    // set cache
    setCache(fullUrl, data, ex)

    return [data, response]
}
export async function getList<ListItem extends Item>({
    url,
    variables,
    ex,
    all,
    pause,
}: GetListProps, req: NextRequest | NextApiRequest): Promise<[List<ListItem>, Response | null]> {
    if (pause) return [null, null]
    if (!all) return get<List<ListItem>>({ url, variables, ex }, req)

    // fetch the first page
    const [firstPage, response] = await get({ url, variables, ex }, req)

    if (!firstPage.next) return [firstPage, response]

    const limit = variables.limit || 20
    const pagesToGo = Math.floor(firstPage.count / limit) // pages to go
    const pagesToFetch = Math.min(pagesToGo, 50) // maximum 50 pages

    const pages = await Promise.all(
        [...Array(pagesToFetch)].map(async (_, i) => {
            const [page] = await get({
                url,
                variables: {
                    ...variables,
                    offset: (i + 1) * limit, // 24, 48, 72, etc
                },
                ex,
            }, req)
            return page
        })
    )

    let results = [...firstPage.results]

    pages.forEach((page) => {
        results = [...results, ...page.results]
    })

    firstPage.results = results

    return [firstPage, response]
}

export async function getItem<GetItem extends Item>({
    url,
    variables,
    ex,
    pause,
}: GetItemProps, req: NextRequest | NextApiRequest): Promise<[GetItem | null, Response | null]> {
    if (pause) return [null, null]
    return get<GetItem>({ url, variables, ex }, req)
}

export async function getRelation<Item = any>({
    url,
    variables,
    ex,
    pause,
}: GetRelationProps, req: NextRequest | NextApiRequest): Promise<[Item | null, Response | null]> {
    if (pause) return [null, null]
    const [list, ...rest] = await get({ url, variables, ex }, req)
    if (list.count !== 1) return [null, ...rest]
    return [list.results[0], ...rest]
}

export const getAggregations = async ({
    url,
    aggregation,
    variables,
}: GetAggregationProps, req: NextRequest | NextApiRequest): Promise<Aggregations> => {
    const aggregationVariables = Object.keys(variables).reduce((obj, key) => {
        if ((aggregation.exclude || []).includes(key)) return obj
        return {
            ...obj,
            [key]: variables[key],
        }
    }, {})

    const [aggregations] = await get<AggregationResponse>({
        url,
        variables: {
            ...aggregationVariables,
            limit: 0,
            offset: 0,
            aggregations: Object.values(aggregation.keys).join(','),
        },
    }, req)

    const data =
        aggregations &&
        filterEmptyAggregations(aggregations.aggregations, aggregation.base)

    return {
        url,
        data,
    }
}
